// Here you can add other styles
.aster-solid-arc:hover {
  transform: scale(1.1);
}

.aster-solid-arc {
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    stroke: #e4e5e6;
}

.aster-circle-score {
    stroke: #e4e5e6;
}

.aster-score {
  line-height: 1;
  font-weight: bold;
  font-size: 400%;
 }

.card-body.nu-card-body{
  padding: 12px;
}

.dashboard-card-weight{
  font-size: 11px;
  vertical-align: super;
}

 .dashboard-card {

 }


.dashboard-widget-progress {
   height: 5px;
}

.dashboard-text-purple {
  color: #6f42c1;
}

.dashboard-chart-summary {
  font-size: 400%;
  position: absolute;
  right: 0;
  bottom: 55px;

}

.dashboard-chart-summary-title {
  position: absolute;
  right: 0;
  bottom: 40px;
}

.dashboard-chart-card-title {
  padding-bottom: 10px;
  color: #acb5bc;
}

.dashboard-chart-card-close {
  position: absolute;
  right: 0;
  text-align: right;
  padding-right: 35px;
}

.doughnut-dashboard-summary {
  position: absolute;
  left: 0;
}

.progress-vertical {
  width: 160px;
  height: 80px;
  -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
  transform: rotate(-90deg);
}

.progress-vertical-card {
  position: absolute;
  right: 0;
}

.progress-vertical-title {
  width: 150px;
  height: 90px;
  -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
  transform: rotate(90deg);
  position: absolute;
  left: 0;
  bottom:50;
}

.progress-vertical-title-bar {
  width: 60px;
  height: 150px;
  -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
  transform: rotate(90deg);
  position: absolute;
  left: 10px;
}

.progress-vertical-chart {
  width: 170px;
  height: 70px;
  position: absolute;
  bottom: 0;
  left:35px;
  color: red;
}

.dashboard-line-chart-body {
  padding: 0;
  padding-bottom: 4.3rem;
}

.dashboard-tab-content-border {
  border-color: #fff;
  border-top-color: #c8ced3;
}

#dashboard-line-chart-firt-tab {
  border-left-color: #fff;
}

.application-card-border {
  border: 0;
}

.scorecard-assessmentmodel-dropdown {
  font-size: 16px;
}

.scorecard-assessmentmodel-label-allignment {
  line-height: 2.5;
}

div.scorecard-assessmentmodel-label-allignment label {
  color: grey;
  font-variant: 'all-small-caps';
}

.nu-tabs.nav.nav-tabs li a.active{
  border-top: 3px solid black;
}

.nu-aster-legend.transparency{
  color: #20a8d8;
}

.nu-aster-legend.bias{
  color: #ffc107;
}

.nu-aster-legend.ethics{
  color: #4dbd74;
}

.nu-aster-legend.privacy{
  color: #f86c6b;
}

.nu-aster-legend.performance{
  color: #6f42c1;
}

.nu-switch-label {
    width: 62px;

    .switch-slider {
    &::before {
      z-index: 2;
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 1px;
      z-index: 1;
      width: 50%;
      margin-top: -.5em;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      color: grey;
      text-align: center;
      text-transform: uppercase;
      content: attr(data-unchecked);
      transition: inherit;
    }
  }


  .switch-input:checked ~ .switch-slider {
   &::before {
     transform: translateX(58 - $switch-height);
   }
   &::after {
     left: 1px;
     color: #fff;
     content: attr(data-checked);
   }
 }
}

#detailedTab {
  display: "none";
}

.nu-switch-table {
  padding: 0 14px 0 12px;
}

.nu-switch-label.transparency {
  background-color: #20a8d8;
}

.nu-switch-label.bias {
  background-color: #ffc107;
}

.nu-switch-label.ethics {
  background-color: #4dbd74;
}

.nu-switch-label.privacy {
  background-color: #f86c6b;
}

.nu-switch-label.performance {
  background-color: #6f42c1;
}

.nu-switch-label.transparency.active {
  background-color: transparent;
}

.nu-switch-label.bias.active {
  background-color: transparent;
}

.nu-switch-label.ethics.active {
  background-color: transparent;
}

.nu-switch-label.privacy.active {
  background-color: transparent;
}

.nu-switch-label.performance.active {
  background-color: transparent;
}

.nu-tabs.nav.nav-tabs.transparency li a.active{
  border-top: 3px solid #20a8d8;
}

.nu-tabs.nav.nav-tabs.bias li a.active{
  border-top: 3px solid #ffc107;
}

.nu-tabs.nav.nav-tabs.ethics li a.active{
  border-top: 3px solid #4dbd74;
}

.nu-tabs.nav.nav-tabs.privacy li a.active{
  border-top: 3px solid #f86c6b;
}

.nu-tabs.nav.nav-tabs.performance li a.active{
  border-top: 3px solid #6f42c1;
}


.nu-dashboard-card {
  margin-top: 3px;
  margin-bottom: 3px;
}

.nu-dashboard-card.active{
  margin-top: 0px;
  margin-bottom: 0px;
}

.dashboard-card.transparency.active{
    border: 3px solid #20a8d8;
}

.dashboard-card.bias.active{
    border: 3px solid #ffc107;
}

.dashboard-card.ethics.active{
    border: 3px solid #4dbd74;
}

.dashboard-card.privacy.active{
    border: 3px solid #f86c6b;
}

.dashboard-card.performance.active{
    border: 3px solid #6f42c1;
}


.scorecard-transparency {
    color: #20a8d8;
}

.scorecard-bias {
    color: #ffc107;
}

.scorecard-ethics {
    color: #4dbd74;
}

.scorecard-privacy {
    color: #f86c6b;
}

.scorecard-performance {
    color: #6f42c1;
}

// Login

.nu-login-banner{
  width: 100%;
  background: white;
  height: 180px;
  position: absolute;
  z-index:9999;
  top: 0px;
}

.nu-login-logo{
  background-image: url("assets/img/MTI/nuenergylogo.png");
  height: 223px;
  width: 755px;
  position: absolute;
  background-repeat: no-repeat;
}

.nu-login-bg-img{
  background-image: url("assets/img/MTI/nu-bg.png");
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 180px;
  opacity: 0.9;
}

.nu-login-box{
  background: rgba(1,1,1,0.5);
  border: none;
}

.nu-login-box .title{
   color: white;
}

.nu-login-banner .title{
  text-align: right;
  margin-top: 50px;
  margin-right: 70px;
}

.nu-login-banner .tagline{
  text-align: right;
  margin-right: 70px;
  color: #20a8d8;
}


// BACKGROUND - POWEREDBY
body{
   background-image: url("assets/img/MTI/nu-energy-powered.png");
   background-position: right bottom;
   background-repeat: no-repeat;
}

.nu-score-card-dropdown .label{
   line-height: 0.65;
   padding-top: 5px;
}


// SPINNER
.loading-background {
                align-items: center;
                justify-content: center;
                background: rgba(0, 0, 0, 0.4);
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                position: absolute;
                display: flex;
                z-index: 700;

}


.spinner-animated  {
  position: absolute;
  left: 50%;
  margin: 0px auto;
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  z-index: 900;
}

.spinner-animated:before,
.spinner-animated:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}
.spinner-animated:before {
  animation: ball1 1s infinite;
  background-color: #7a507d;
  box-shadow: 30px 0 0 #e8954e;
  margin-bottom: 10px;
}
.spinner-animated:after {
  animation: ball2 1s infinite;
  background-color: #4677ae;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334 ;
  }
  50% {
    box-shadow: 0 0 0 #f8b334 ;
    margin-bottom: 0;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #f8b334 ;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }
  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}

.nu-tabs.nav.nav-tabs li.active a{
  border-top: 3px solid;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.app-header .navbar-brand {
    width: 55px !important;
    height: 55px !important;
}

input[type=checkbox] { display:none; }
input[type=checkbox] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}
input[type=checkbox] + label:before { content: "\f096"; }
input[type=checkbox] + label:before { letter-spacing: 10px; }
input[type=checkbox]:checked + label:before { content: "\f046"; }
input[type=checkbox]:checked + label:before { letter-spacing: 5px; }
